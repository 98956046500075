import { useStaticQuery, graphql } from "gatsby";

const GlobalData = () =>
  useStaticQuery(graphql`
    query GlobalDataQuery {
      global: datoCmsGlobalAsset {
        stickerCallout {
          alt
          url(imgixParams: { auto: "enhance", q: 100, w: "560" })
          width
          height
        }
        horizontalCloud {
          alt
          url(imgixParams: { auto: "enhance", q: 100, w: "1280" })
          width
          height
        }
        verticalCloud {
          alt
          url(imgixParams: { auto: "enhance", q: 100, w: "1280" })
          width
          height
        }
        fallingLogo {
          url(imgixParams: { auto: "enhance", q: 100, w: "1280" })
          alt
          width
          height
        }
      }
    }
  `);

export default GlobalData;
