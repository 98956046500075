import * as React from 'react'
import GlobalData from '../data/global-images'

const SecondaryClouds = () => {
    const images = GlobalData();
    return (
        <div className="secondary-page-clouds">
            <div className='cloud-background'>
                <img className='cloud-animate' src={images.global.horizontalCloud.url} alt={images.global.horizontalCloud.alt}></img>
                <img className='cloud-animate' src={images.global.horizontalCloud.url} alt={images.global.horizontalCloud.alt}></img>
            </div>
            <div className='cloud-foreground'>
                <img className='cloud-animate-fast' src={images.global.horizontalCloud.url} alt={images.global.horizontalCloud.alt}></img>
                <img className='cloud-animate-fast' src={images.global.horizontalCloud.url} alt={images.global.horizontalCloud.alt}></img>
            </div>
        </div>
    )
}
export default SecondaryClouds